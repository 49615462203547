import {
  sand,
  sandDark,
  violet,
  violetA,
  violetDark,
  violetDarkA,
} from "@radix-ui/colors";

const accent = {
  accent1: violet.violet1,
  accent2: violet.violet2,
  accent3: violet.violet3,
  accent4: violet.violet4,
  accent5: violet.violet5,
  accent6: violet.violet6,
  accent7: violet.violet7,
  accent8: violet.violet8,
  accent9: violet.violet9,
  accent10: violet.violet10,
  accent11: violet.violet11,
  accent12: violet.violet12,
};

const accentA = {
  accentA1: violetA.violetA1,
  accentA2: violetA.violetA2,
  accentA3: violetA.violetA3,
  accentA4: violetA.violetA4,
  accentA5: violetA.violetA5,
  accentA6: violetA.violetA6,
  accentA7: violetA.violetA7,
  accentA8: violetA.violetA8,
  accentA9: violetA.violetA9,
  accentA10: violetA.violetA10,
  accentA11: violetA.violetA11,
  accentA12: violetA.violetA12,
};

const accentDark = {
  accent1: violetDark.violet1,
  accent2: violetDark.violet2,
  accent3: violetDark.violet3,
  accent4: violetDark.violet4,
  accent5: violetDark.violet5,
  accent6: violetDark.violet6,
  accent7: violetDark.violet7,
  accent8: violetDark.violet8,
  accent9: violetDark.violet9,
  accent10: violetDark.violet10,
  accent11: violetDark.violet11,
  accent12: violetDark.violet12,
};

const accentADark = {
  accentA1: violetDarkA.violetA1,
  accentA2: violetDarkA.violetA2,
  accentA3: violetDarkA.violetA3,
  accentA4: violetDarkA.violetA4,
  accentA5: violetDarkA.violetA5,
  accentA6: violetDarkA.violetA6,
  accentA7: violetDarkA.violetA7,
  accentA8: violetDarkA.violetA8,
  accentA9: violetDarkA.violetA9,
  accentA10: violetDarkA.violetA10,
  accentA11: violetDarkA.violetA11,
  accentA12: violetDarkA.violetA12,
};

export const colors = {
  ...sand,
  ...accent,
  ...accentA,
};

export const colorsDark = {
  ...sandDark,
  ...accentDark,
  ...accentADark,
};

export type Colors = typeof colors;

export type ColorName = keyof Colors;
