import React from "react";
import ReactDOM from "react-dom/client";
import { Website } from "./Website";

function run() {
	const container = document.createElement("main");
	document.body.appendChild(container);
	ReactDOM.createRoot(container).render(<Website />);
}

run();
