import {
	blackA,
	blue,
	crimson,
	jade,
	sand,
	sandA,
	sandDark,
	violet,
	whiteA,
} from "@radix-ui/colors";
import { range } from "lodash-es";
import React, { useEffect } from "react";
import Logo from "./logos/Logo.svg";
import CircleLogo from "./logos/LogoCircle.svg";
import MessageTail from "./logos/MessageTail.svg";

import { PurseProvider, useStyles } from "purse-styles";
import { useState } from "react";
import { BorderRadius, BorderWidth, Box, Shadow, Spacer, Spacing } from "./Box";
import { Icon, IconName } from "./Icon";
import "./style.css"; // Updated to include new styling
import { Text, TextSize, TextWeight } from "./Text";
import { ThemeProvider, useTheme } from "./Theme";
import { Tooltip } from "./Tooltip";
import {
	mapResponsiveValue,
	Responsive,
	useResponsiveStyles,
} from "./useResponsiveStyles";

function useMediaQuery(query: string) {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia(query);
		setMatches(mediaQuery.matches);

		const handleMatchChange = (e: MediaQueryListEvent) => {
			setMatches(e.matches);
		};

		mediaQuery.addEventListener("change", handleMatchChange);
		return () => {
			mediaQuery.removeEventListener("change", handleMatchChange);
		};
	}, [query]);

	return matches;
}

type HeroTitleProps = {
	children: string;
};

const HeroTitle: React.FC<HeroTitleProps> = ({ children }) => {
	return (
		<Text
			weight={TextWeight.bold}
			align="center"
			balance
			style={{
				maxWidth: 560,
				fontSize: {
					initial: 66,
					"<md": 48,
				},
				lineHeight: {
					initial: "64px",
					"<md": "44px",
				},
				letterSpacing: {
					initial: "-0.03em",
					"<md": "-0.02em",
				},
			}}
		>
			{children}
		</Text>
	);
};

type HeroSubtitleProps = {
	children: (string | React.ReactNode)[];
};

const HeroSubtitle: React.FC<HeroSubtitleProps> = ({ children }) => {
	return (
		<Text
			size={{ initial: TextSize.xl, "<md": TextSize.lg }}
			align="center"
			style={{ maxWidth: 560 }}
		>
			{children}
		</Text>
	);
};

type CallOutSectionTextProps = {
	children: string | (string | React.ReactNode)[];
	fancy?: boolean;
};

const CallOutSectionText: React.FC<CallOutSectionTextProps> = ({
	children,
	fancy,
}) => {
	return (
		<Text
			italic={fancy}
			fancy={fancy}
			color="sand12"
			style={{
				maxWidth: 560,
				fontSize: {
					initial: 40,
					"<md": 24,
				},
				lineHeight: {
					initial: "42px",
					"<md": "28px",
				},
				letterSpacing: {
					initial: "-0.005em",
					"<md": 0,
				},
			}}
		>
			{children}
		</Text>
	);
};

type SectionSubtitleProps = {
	children: string | (string | React.ReactNode)[];
};

const SectionSubtitle: React.FC<SectionSubtitleProps> = ({ children }) => {
	return (
		<Text
			as="p"
			align="center"
			light
			size={TextSize.base}
			balance
			style={{ maxWidth: 520 }}
		>
			{children}
		</Text>
	);
};

type SectionTitleProps = {
	children: string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => {
	return (
		<Text
			size={{ initial: TextSize.xxl, "<md": TextSize.xl }}
			weight={TextWeight.semibold}
			color="sand12"
			align="center"
			balance
			style={{ maxWidth: 520 }}
		>
			{children}
		</Text>
	);
};

// Editing comment to merge to prod
export function Website() {
	const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

	return (
		<ThemeProvider mode="light">
			<PurseProvider>
				<Box
					className={isDarkMode ? "dark" : "light"}
					column
					background="sand1"
					style={{ color: sand.sand12 }}
				>
					<Box
						column
						align="stretch"
						style={{ position: "relative", zIndex: 2 }}
					>
						<HeaderSection />
						{/* <CalloutForIndividuals /> */}
						{/* <AverageTimeCard /> */}
						{/* <Spacer height={80} /> */}
						{/* <CurrentToolsAreFailingCard /> */}
						{/* <CallOutHeaderCard /> */}
						<RightInformationSection />
						<PersonalResearchAssistantSection />
						<SchedulingSection />
						<Divider />
						<Footer />
					</Box>
				</Box>
			</PurseProvider>
		</ThemeProvider>
	);
}

const iPhoneDeviceBackground = new URL(
	"../media/iPhone_frame.png",
	import.meta.url,
);

const HeaderSection: React.FC = () => {
	return (
		<>
			<Box
				column
				align="stretch"
				style={{ overflow: "hidden", position: "relative" }}
			>
				<div
					style={{
						position: "absolute",
						inset: 0,
						zIndex: -1,
					}}
				>
					<div
						className="hero-gradient"
						style={{ position: "absolute", inset: 0 }}
					/>
					<div
						style={{
							position: "absolute",
							inset: 0,
							transform: `translateY(-50%)`,
						}}
					>
						<PolarCoordinateGrid
							initialR={80}
							circleCount={3}
							rStep={120}
							color={sandA.sandA3}
						/>
					</div>
				</div>
				<Spacer height={24} />
				<Box gap={4} paddingHorizontal={Spacing.lg} align="center">
					<Logo width={24} height={24} />
					<Text size={TextSize.lg} weight={TextWeight.semibold} color="sand12">
						Saffron
					</Text>
				</Box>
				<Spacer
					height={{
						initial: 96,
						"<md": 64,
					}}
				/>
				<Box column align="center" paddingHorizontal={Spacing.lg}>
					<HeroTitle>Take control of your family's healthcare</HeroTitle>
				</Box>
				<Spacer height={32} />
				<Box column align="center" paddingHorizontal={Spacing.lg}>
					<HeroSubtitle>
						<Icon
							name="Hospital"
							size={{ initial: 20, "<md": 18 }}
							color="accent11"
							style={{ transform: `translateY(2px)` }}
						/>{" "}
						Find care,{" "}
						<Icon
							name="CircleDollarSign"
							size={{ initial: 20, "<md": 18 }}
							color="accent11"
							style={{ transform: `translateY(3px)` }}
						/>{" "}
						compare costs,{" "}
						<Icon
							name="CalendarDays"
							size={{ initial: 20, "<md": 18 }}
							color="accent11"
							style={{ transform: `translateY(3px)` }}
						/>{" "}
						schedule appointments, and{" "}
						<Icon
							name="Reply"
							size={{ initial: 20, "<md": 18 }}
							color="accent11"
							style={{ transform: `translateY(3px)` }}
						/>{" "}
						automate follow-up calls for your family
					</HeroSubtitle>
				</Box>
				<Spacer height={32} />
				<Box column align="center">
					<ContactUsButton />
				</Box>
				<Spacer height={36} />
				<Text align="center" fancy italic size={TextSize.xl}>
					Watch the demo
				</Text>
				<Spacer height={8} />
				<Box
					column
					align="center"
					paddingHorizontal={Spacing.lg}
					style={{ width: "100%", zIndex: 3 }}
				>
					<Box align="center" justify="center" style={{ position: "relative" }}>
						<Box style={{ border: `12px solid transparent` }}>
							<video
								style={{
									height: 540,
									width: "auto",
									zIndex: 1,
									borderRadius: 34,
								}}
								controls
							>
								<source src={SaffronDemo.toString()} type="video/mp4" />
							</video>
						</Box>
						<img
							src={iPhoneDeviceBackground.toString()}
							style={{
								position: "absolute",
								pointerEvents: "none",
								top: "50% ",
								left: "50%",
								transform: `translate(-50%, -50%) scale(1.044)`,
								aspectRatio: "434 / 882",
								height: 540,
								width: "auto",
								objectFit: "contain",
								objectPosition: "center",
								zIndex: 2,
							}}
						/>
					</Box>
				</Box>
				<Spacer height={{ initial: 120, "<md": 80 }} />
				<CalloutForIndividuals />
			</Box>
		</>
	);
};

type StatCardProps = {
	stat: string;
	unit: string;
	description: string;
	tooltipContent: string;
	tooltipSource: string;
	tooltipLink: string;
};

const StatCard: React.FC<StatCardProps> = ({
	stat,
	unit,
	description,
	tooltipContent,
	tooltipSource,
	tooltipLink,
}) => {
	return (
		<Box
			column
			align="center"
			paddingVertical={Spacing.md}
			paddingHorizontal={Spacing.md}
			gap={Spacing.sm}
		>
			<Box align="flex-end" gap={Spacing.xxxs}>
				<Text
					style={{
						fontSize: {
							initial: 84,
							"<md": 64,
						},
						lineHeight: {
							initial: "84px",
							"<md": "64px",
						},
					}}
					color="accent9"
					weight={TextWeight.medium}
				>
					{stat}
				</Text>
				<Text color="accent9" weight={TextWeight.semibold} size={TextSize.lg}>
					{unit}
				</Text>
			</Box>
			<Text
				light
				size={TextSize.base}
				balance
				align="center"
				style={{ maxWidth: 220 }}
			>
				{description}
				{tooltipContent && tooltipSource && (
					<Tooltip
						content={tooltipContent}
						source={tooltipSource}
						url={tooltipLink}
					/>
				)}
			</Text>
		</Box>
	);
};

const AverageTimeCard: React.FC = () => {
	return (
		<ThemeProvider mode="dark">
			<Box
				column
				align="center"
				style={{
					position: "relative",
					padding: {
						initial: 60,
						"<md": 40,
					},
					zIndex: 2,
				}}
			>
				<Box
					background="sand2"
					className="grid-background"
					borderTopWidth={BorderWidth.md}
					borderBottomWidth={BorderWidth.md}
					borderColor="sand6"
					style={{
						position: "absolute",
						overflow: "hidden",
						inset: "-80px 0",
						transform: `skewY(-2.7deg)`,
						zIndex: -1,
						boxShadow: `0 0 10px 2px inset ${sandDark.sand1}`,
					}}
				/>
				<Box
					column
					align="center"
					paddingVertical={Spacing.md}
					paddingHorizontal={Spacing.md}
				>
					<SectionTitle>
						Today, people are forced to give up their own aspirations to support
						their family’s health
					</SectionTitle>
					<Spacer height={48} />
					<Box
						gap={{ initial: Spacing.lg, "<md": Spacing.md }}
						wrap="wrap"
						justify="center"
					>
						<StatCard
							stat="21.3"
							unit="%"
							description="of adults have intensive caregiving duties "
							tooltipContent="Intensive caregiving duties include caring for an adult or a child with special needs."
							tooltipSource="AARP and National Alliance for Caregiving. Caregiving in the United States 2020. Washington, DC: AARP. May 2020."
							tooltipLink="https://doi.org/10.26419/ppi.00103.001"
						/>
						<StatCard
							stat="24"
							unit="hrs / wk"
							description="are spent on caregiving "
							tooltipContent="On average, caregivers in the U.S. spend 24 hours per week providing care."
							tooltipSource="AARP and National Alliance for Caregiving. Caregiving in the United States 2020. Washington, DC: AARP. May 2020."
							tooltipLink="https://doi.org/10.26419/ppi.00103.001"
						/>
						<StatCard
							stat="61"
							unit="%"
							description="of caregivers experience career setbacks "
							tooltipContent="Setbacks include reducing hours, taking a leave of absence, receiving performance warnings, declining promotions, quitting, and losing job benefits."
							tooltipSource="AARP and National Alliance for Caregiving. Caregiving in the United States 2020. Washington, DC: AARP. May 2020."
							tooltipLink="https://doi.org/10.26419/ppi.00103.001"
						/>
					</Box>
					<Spacer height={48} />
					<Text
						size={{ initial: TextSize.xl, "<md": TextSize.lg }}
						weight={TextWeight.medium}
						color="sand12"
						align="center"
						balance
						style={{ maxWidth: 520 }}
					>
						You shouldn’t have to sacrifice your goals to care for your family.
					</Text>
					<Text
						color="accent10"
						size={{ initial: TextSize.xl, "<md": TextSize.lg }}
						weight={TextWeight.medium}
						align="center"
						balance
					>
						Help should be available when you need it.
						{/* The support you need should be right at your fingertips. */}
					</Text>

					<Spacer height={32} />
				</Box>
			</Box>
		</ThemeProvider>
	);
};

const CurrentToolsAreFailingCard: React.FC = () => {
	return (
		<ThemeProvider mode="dark">
			<Box
				column
				align="center"
				style={{
					position: "relative",
					paddingBottom: { initial: 64, "<md": 48 },
					zIndex: 2,
				}}
			>
				<Box
					className="grid-background-backward"
					borderTopWidth={BorderWidth.md}
					borderBottomWidth={BorderWidth.md}
					shadow={Shadow.xl}
					borderColor="sand6"
					background="sand1"
					style={{
						position: "absolute",
						overflow: "hidden",
						inset: "-80px 0",
						transform: `skewY(2.7deg)`,
						zIndex: -1,
					}}
				/>
				<Box
					column
					align="center"
					paddingVertical={Spacing.md}
					paddingHorizontal={Spacing.md}
				>
					<SectionTitle>Current tools for caregivers fall short</SectionTitle>
					<Spacer height={48} />

					<Box gap={Spacing.lg} wrap justify="center" align="stretch">
						<Card
							icon="Grid2x2X"
							title="Missing Key Info"
							description="Important sources and personal info are often left out, making tools less personalized"
						/>
						<Card
							icon="ShieldOff"
							title="Less Control"
							description="Other solutions leave you uninformed and exclude you from the care-finding process"
						/>
					</Box>
					<Spacer height={Spacing.lg} />
					<Box gap={Spacing.lg} wrap justify="center" align="stretch">
						<Card
							icon="Snail"
							title="Slow Responses"
							description="Getting care takes too long due to slow communication and over-reliance on manual effort"
						/>
						<Card
							icon="CircleDollarSign"
							title="Expensive Fees"
							description="Traditional services charge a lot, regardless of usage, making them inaccessible"
						/>
					</Box>
				</Box>
			</Box>
		</ThemeProvider>
	);
};

type CardProps = {
	icon: IconName;
	title: string;
	description: string;
};

const Card: React.FC<CardProps> = ({ icon, title, description }) => {
	return (
		<Box
			column
			paddingHorizontal={Spacing.md}
			paddingVertical={Spacing.md}
			borderWidth={BorderWidth.md}
			borderRadius={BorderRadius.md}
			shadow={Shadow.md}
			basis={320}
			gap={Spacing.xxs}
			background="sand4"
			style={{ minHeight: 140 }}
		>
			<Box align="center" gap={Spacing.xs}>
				<Icon name={icon} size={18} color="sand12" />
				<Text weight={TextWeight.semibold} color="sand12">
					{title}
				</Text>
			</Box>
			<Text as="p" light>
				{description}
			</Text>
		</Box>
	);
};

const CallOutHeaderCard: React.FC = () => (
	<div style={{ position: "relative" }}>
		<div
			className="call-out-section-gradient"
			style={{ position: "absolute", inset: "0 0 -320px 0", zIndex: -1 }}
		/>
		<Box
			column
			paddingHorizontal={Spacing.md}
			style={{
				paddingBottom: { "<md": 160, initial: 240 },
				zIndex: 10,
			}}
			align="center"
		>
			<Box
				column
				align="center"
				gap={Spacing.md}
				paddingHorizontal={Spacing.xl}
				paddingVertical={Spacing.xl}
				borderRadius={BorderRadius.xl}
				borderColor="accent8"
				background="sand2"
				borderWidth={BorderWidth.lg}
				shadow={Shadow.xl}
				style={{
					zIndex: 8,
					maxWidth: `min(480px , 100%)`,
				}}
			>
				<SectionTitle>
					Saffron approaches care management differently
				</SectionTitle>
				<SectionSubtitle>
					<Icon
						name="Database"
						size={{ initial: 18, "<md": 16 }}
						color="accent11"
						style={{ transform: `translateY(2px)` }}
					/>{" "}
					Detailed health data, an{" "}
					<Icon
						name="BotMessageSquare"
						size={{ initial: 18, "<md": 16 }}
						color="accent11"
						style={{ transform: `translateY(3px)` }}
					/>{" "}
					AI research assistant, and{" "}
					<Icon
						name="Workflow"
						size={{ initial: 18, "<md": 16 }}
						color="accent11"
						style={{ transform: `translateY(3px)` }}
					/>{" "}
					complex task automation, for a{" "}
					<Icon
						name="Percent"
						size={{ initial: 18, "<md": 16 }}
						color="accent11"
						style={{ transform: `translateY(3px)` }}
					/>{" "}
					fraction of the cost
				</SectionSubtitle>
			</Box>
		</Box>
	</div>
);

// Colors with icons
const CalloutForIndividuals: React.FC = () => {
	const imageMaxHeightStyle = useResponsiveStyles({
		maxHeight: {
			initial: "80vh",
			"<md": "50vh",
		},
	});

	return (
		<div>
			<Box
				column={{
					initial: false,
					"<lg": true,
				}}
				paddingHorizontal={Spacing.xxl}
				align="center"
				justify="center"
				gap={Spacing.xxl}
				style={{ maxWidth: 960, margin: "auto" }}
			>
				<Box column align="center" grow paddingVertical={Spacing.lg}>
					<CallOutSectionText>
						Saffron is a{" "}
						<Box inline align="center">
							<FeatureBadge
								icon="BookUser"
								text="provider/facility directory"
								color={blue.blue9}
							/>
							,
						</Box>
						<Box inline align="center">
							<FeatureBadge
								icon="Thermometer"
								text="symptom assessment tool"
								color={crimson.crimson9}
							/>
							,
						</Box>
						<Box inline align="center">
							<FeatureBadge
								icon="CircleUser"
								text="patient portal"
								color={jade.jade9}
							/>
							,
						</Box>{" "}
						and{" "}
						<Box inline align="center">
							<FeatureBadge
								icon="Headset"
								text="AI call assistant"
								color={violet.violet9}
							/>
						</Box>{" "}
						<CallOutSectionText fancy>
							{" "}
							all in one chat interface
						</CallOutSectionText>
					</CallOutSectionText>
				</Box>
				<Box align="center">
					<img
						src={AppScreenshotImage.toString()}
						className={imageMaxHeightStyle}
						style={{
							width: "auto",
							height: "100%",
							maxHeight: "65vh",
							objectFit: "contain",
							aspectRatio: "774 / 1586",
						}}
						alt="Saffron app screenshot"
					/>
				</Box>
			</Box>
			<Spacer
				height={{
					initial: 120,
					"<md": 48,
				}}
			/>
		</div>
	);
};

type FeatureBadgeProps = {
	icon: IconName;
	text: string;
	color: string;
};

const FeatureBadge: React.FC<FeatureBadgeProps> = ({ icon, text, color }) => {
	return (
		<Box
			inline
			gap={Spacing.xxs}
			align="baseline"
			style={{
				backgroundColor: color,
				padding: { initial: "4px 16px 4px 10px", "<md": "0px 12px 2px 8px" },
				borderRadius: 10000,
				margin: "4px 0",
				whiteSpace: "nowrap",
			}}
		>
			<ThemeProvider mode="dark">
				<Icon
					name={icon}
					color="sand12"
					size={{ initial: 32, "<md": 20 }}
					style={{ transform: `translateY(3px)` }}
				/>{" "}
				<Text color="sand12">{text}</Text>
			</ThemeProvider>
		</Box>
	);
};

type DividerProps = {
	vertical?: Responsive<boolean>;
	className?: string;
	size?: number;
};

const Divider: React.FC<DividerProps> = ({ vertical, size = 180 }) => {
	const height = mapResponsiveValue(vertical, (vertical): number =>
		vertical ? size : 0,
	);
	const width = mapResponsiveValue(vertical, (vertical): number =>
		vertical ? 0 : size,
	);

	const className = useResponsiveStyles({ height, width });

	return (
		<Box
			className={className}
			borderLeftWidth={mapResponsiveValue(vertical, (vertical) =>
				vertical ? BorderWidth.md : undefined,
			)}
			borderTopWidth={mapResponsiveValue(vertical, (vertical) =>
				vertical ? undefined : BorderWidth.md,
			)}
			borderColor="sand6"
			alignSelf="center"
		/>
	);
};

const RightInformationSection: React.FC = () => (
	<Section
		title="The best care starts with the right information"
		description="Access the latest information from trusted sources to help you find top
			doctors and clinics, personalized for you and your family’s needs."
	>
		<RightInformationDiagram />
	</Section>
);

const DrChoudharyImage = new URL("../media/DrChoudhary.png", import.meta.url);
const AppScreenshotImage = new URL(
	"../media/SaffronExample.png",
	import.meta.url,
);
const SaffronDemo = new URL("../media/saffron_demo.mp4", import.meta.url);

type InfoSourceBadge = {
	icon: IconName;
	text: string;
	top: number;
	left: number;
};

const badges: InfoSourceBadge[] = [
	{
		icon: "FileUser",
		text: "Personal health history",
		top: 24.5,
		left: 20,
	},
	{
		icon: "Hospital",
		text: "EHR systems",
		top: 14.4,
		left: 50.2,
	},
	{
		icon: "BookMarked",
		text: "Provider directories",
		top: 26,
		left: 80,
	},
	{
		icon: "TestTube",
		text: "Clinical research",
		top: 44.7,
		left: 85.9,
	},
	{
		icon: "Globe",
		text: "Online reviews and forums",
		top: 62.5,
		left: 82,
	},
	{
		icon: "Capital",
		text: "Government programs",
		top: 60.8,
		left: 20.3,
	},
	{
		icon: "FileText",
		text: "Insurance claims",
		top: 42.9,
		left: 14,
	},
];

const RightInformationDiagram: React.FC = () => {
	const theme = useTheme();
	const mobileOnlyStyle = useResponsiveStyles({
		display: { initial: "none", "<md": "flex" },
	});
	const desktopOnlyStyle = useResponsiveStyles({
		display: { initial: "flex", "<md": "none" },
	});

	return (
		<Box column align="center" style={{ width: `clamp(320px,100%,640px)` }}>
			<Box
				wrap
				justify="center"
				gap={Spacing.xs}
				className={mobileOnlyStyle}
				style={{ marginBottom: -60, zIndex: 5 }}
			>
				{badges.map((badge, index) => (
					<Badge key={index} icon={badge.icon} text={badge.text} />
				))}
			</Box>
			<div
				style={{
					position: "relative",
					width: "100%",
					aspectRatio: "16 / 11",
				}}
			>
				<div style={{ position: "absolute", inset: 0 }}>
					<PolarCoordinateGrid
						initialR={50}
						rStep={50}
						circleCount={4}
						color={sand.sand6}
					/>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 640 440"
						fill="none"
						style={{ position: "absolute", inset: 0 }}
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M320.285 117.742V178.407"
							stroke={violet.violet9}
							strokeWidth={1.5}
						/>
						<path
							d="M399.868 166.014L358.119 195.368"
							stroke={violet.violet6}
							strokeWidth={1.5}
						/>
						<path
							d="M409 222.112H385.517"
							stroke={violet.violet9}
							strokeWidth={1.5}
						/>
						<path
							d="M273.318 158.185L285.06 175.145"
							stroke={violet.violet6}
							strokeWidth={1.5}
						/>
						<path
							d="M212 177.755L278.536 205.805"
							stroke={violet.violet9}
							strokeWidth={1.5}
						/>
						<path
							d="M285.06 291.258L298.106 261.252"
							stroke={violet.violet6}
							strokeWidth={1.5}
						/>
						<path
							d="M235.484 261.251L273.318 241.682"
							stroke={violet.violet9}
							strokeWidth={1.5}
						/>
						<path
							d="M350.291 239.073L393.344 270.384"
							stroke={violet.violet6}
							strokeWidth={1.5}
						/>
					</svg>
				</div>
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: `translate(-50%, -50%)`,
					}}
				>
					<CircleLogo
						width={48}
						height={48}
						style={{
							"--background": theme.colors.accent9,
							"--foreground": theme.colors.sand1,
						}}
					/>
				</div>
				<div
					style={{
						position: "absolute",
						width: 2,
						height: "25%",
						backgroundColor: theme.colors.accent9,
						top: "calc(50% + 20px)",
						left: "50%",
						transform: `translate(-50%, 0)`,
					}}
				/>
				<Box
					paddingHorizontal={Spacing.md}
					style={{
						position: "absolute",
						width: `min(400px, 100vw)`,

						top: "81%",
						left: "50%",
						transform: `translate(-50%, -50%)`,
					}}
				>
					<ProviderCard />
				</Box>

				{badges.map((badge, index) => (
					<Box
						key={index}
						className={desktopOnlyStyle}
						style={{
							position: "absolute",
							transform: `translate(-50%, -50%)`,
							top: `${badge.top}%`,
							left: `${badge.left}%`,
						}}
					>
						<Badge icon={badge.icon} text={badge.text} />
					</Box>
				))}
			</div>
		</Box>
	);
};
const ProviderCard: React.FC = () => {
	const theme = useTheme();
	return (
		<Box
			gap={8}
			align="center"
			paddingVertical={Spacing.xs}
			paddingHorizontal={Spacing.xs}
			borderRadius={BorderRadius.lg}
			borderWidth={BorderWidth.lg}
			borderColor="accent9"
			style={{
				minWidth: 180,
				background: `linear-gradient(${theme.colors.accentA7}, transparent), ${sand.sand1}`,
			}}
		>
			<Box
				align="center"
				width={{ initial: Spacing.xxxl, "<md": Spacing.xxl }}
				height={{ initial: Spacing.xxxl, "<md": Spacing.xxl }}
				shrink={0}
				borderRadius={BorderRadius.full}
				borderWidth={BorderWidth.lg}
				borderColor="sand1"
				style={{ overflow: "hidden" }}
			>
				<img
					src={DrChoudharyImage.toString()}
					style={{ width: "100%", height: "100%" }}
				/>
			</Box>
			<Box column grow shrink gap={2} style={{ minWidth: 0 }}>
				<Text
					size={{
						initial: TextSize.base,
						"<md": TextSize.sm,
					}}
					weight={TextWeight.semibold}
					color="sand12"
				>
					Dr. Choudhary
				</Text>
				<Box column grow shrink>
					<Text
						truncate
						size={{
							initial: TextSize.sm,
							"<md": TextSize.xs,
						}}
						color="sand11"
						style={{ minWidth: 0, flex: 1 }}
					>
						<Icon
							name="Star"
							color="sand12"
							size={{
								initial: 12,
								"<md": 10,
							}}
							fill="sand12"
							style={{ transform: `translateY(1px)` }}
						/>
						<Text
							size={{
								initial: TextSize.sm,
								"<md": TextSize.xs,
							}}
							weight={TextWeight.medium}
							color="sand12"
						>
							{" "}
							Best match{" "}
						</Text>
						— Has 10 years of documented experience treating patients with
						chronic mu...
					</Text>
				</Box>
			</Box>
		</Box>
	);
};

type SectionProps = {
	title: string;
	description: string;
	children: React.ReactNode;
};

const Section: React.FC<SectionProps> = ({ children, title, description }) => {
	return (
		<Box
			column
			align="center"
			paddingVertical={Spacing.md}
			paddingHorizontal={Spacing.md}
		>
			<Box
				column
				align="center"
				style={{ width: `min(880px, 100%)`, overflow: "hidden" }}
			>
				<Divider />
				<Spacer height={36} />
				<SectionTitle>{title}</SectionTitle>
				<Spacer height={16} />
				<SectionSubtitle>{description}</SectionSubtitle>
				<Spacer height={24} />
				{children}
			</Box>
			<Spacer
				height={{
					"<md": Spacing.xl,
					initial: Spacing.xxxl,
				}}
			/>
		</Box>
	);
};

const ProviderDiagram = new URL(
	"../media/ProviderDiagram.png",
	import.meta.url,
);

type PolarCoordinateGridProps = {
	initialR: number;
	rStep: number;
	circleCount: number;

	color: string;
};

const PolarCoordinateGrid: React.FC<PolarCoordinateGridProps> = ({
	initialR,
	rStep,
	circleCount,
	color,
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="-350 -185 700 370"
		>
			<defs>
				<radialGradient id="lineFade" r="60%">
					<stop offset="0%" style={{ stopColor: color, stopOpacity: 1 }} />
					<stop offset="100%" style={{ stopColor: color, stopOpacity: 0 }} />
				</radialGradient>
				<radialGradient id="circleFade" r="100%" fr="45%">
					<stop offset="0%" style={{ stopColor: color, stopOpacity: 0 }} />
					<stop offset="100%" style={{ stopColor: color, stopOpacity: 1 }} />
				</radialGradient>
			</defs>

			{range(circleCount).map((i) => {
				return (
					<circle
						key={i}
						cx={0}
						cy={0}
						r={initialR + rStep * i}
						fill="url(#circleFade)"
						stroke={color}
						strokeWidth={1}
						strokeOpacity={(circleCount - i) / circleCount}
					/>
				);
			})}

			<line
				x1="-247.49"
				y1="-247.49"
				x2="247.49"
				y2="247.49"
				stroke="url(#lineFade)"
				strokeWidth="1"
			/>
			<line
				x1="247.49"
				y1="-247.49"
				x2="-247.49"
				y2="247.49"
				stroke="url(#lineFade)"
				strokeWidth="1"
			/>
		</svg>
	);
};

type BadgeProps = {
	icon: IconName;
	text: string;
	style?: React.CSSProperties;
};

const Badge: React.FC<BadgeProps> = ({ icon, text, style }) => {
	return (
		<Box
			inline
			align="center"
			gap={4}
			borderWidth={BorderWidth.md}
			borderColor="sand6"
			background="sand1"
			borderRadius={BorderRadius.md}
			paddingVertical={Spacing.xxs}
			paddingHorizontal={Spacing.xxs}
			style={{ width: "fit-content", ...style }}
		>
			<Icon light name={icon} size={{ initial: 18, "<sm": 16 }} />
			<Text
				truncate
				light
				size={{
					initial: TextSize.sm,
					"<sm": TextSize.xs,
				}}
				weight={TextWeight.medium}
			>
				{text}
			</Text>
		</Box>
	);
};

function user(content: string): UserMessage {
	return {
		type: "user",
		content,
	};
}
function assistant(content: AssistantMessageBlock[]): AssistantMessage {
	return {
		type: "assistant",
		content,
	};
}

function assistantText(text: string): AssistantMessageBlock {
	return {
		type: "text",
		text,
	};
}
function assistantCallInProgress(): AssistantMessageBlock {
	return {
		type: "call-in-progress",
	};
}
function assistantAccessingProviderProfiles(): AssistantMessageBlock {
	return {
		type: "accessing-provider-profiles",
	};
}
function assistantCallCompleted(): AssistantMessageBlock {
	return {
		type: "call-completed",
	};
}
function assistantAccessingClinicalTrials(): AssistantMessageBlock {
	return {
		type: "accessing-clinical-trials",
	};
}

type UserMessage = {
	type: "user";
	content: string;
};

type AssistantMessageBlock =
	| {
			type: "text";
			text: string;
	  }
	| {
			type: "call-in-progress";
	  }
	| {
			type: "accessing-provider-profiles";
	  }
	| {
			type: "accessing-clinical-trials";
	  }
	| {
			type: "call-completed";
	  };

type AssistantMessage = {
	type: "assistant";
	content: AssistantMessageBlock[];
};

type Message = UserMessage | AssistantMessage;

type UserConversationItemViewProps = {
	content: string;
};

const UserMessageView: React.FC<UserConversationItemViewProps> = ({
	content,
}) => {
	const theme = useTheme();

	return (
		<Box alignSelf="flex-end" style={{ position: "relative", maxWidth: "75%" }}>
			<MessageTail
				width={16}
				color={theme.colors.accent10}
				style={{ position: "absolute", bottom: 0, right: -3 }}
			/>
			<Box
				inline
				background="accent10"
				style={{
					position: "relative",
					padding: "6px 12px",
					borderRadius: 18,
				}}
			>
				<ThemeProvider mode="dark">
					<Text
						color="sand12"
						size={{ initial: TextSize.base, "<md": TextSize.sm }}
					>
						{content}
					</Text>
				</ThemeProvider>
			</Box>
		</Box>
	);
};

type AssistantMessageViewProps = {
	content: AssistantMessageBlock[];
};

const AssistantMessageView: React.FC<AssistantMessageViewProps> = ({
	content,
}) => {
	const theme = useTheme();

	return (
		<Box gap={8} style={{ alignSelf: "Box-start", maxWidth: "90%" }}>
			<div>
				<CircleLogo
					width={24}
					height={24}
					style={{
						"--background": theme.colors.accent9,
						"--foreground": theme.colors.sand1,
					}}
				/>
			</div>
			<Box column align="Box-start" gap={8}>
				{content.map((block) => {
					switch (block.type) {
						case "text":
							return (
								<Text size={{ initial: TextSize.base, "<md": TextSize.sm }}>
									{block.text}
								</Text>
							);
						case "call-in-progress":
							return <Badge icon="PhoneCall" text="Call in progress..." />;
						case "call-completed":
							return <Badge icon="PhoneCall" text="Call completed" />;
						case "accessing-clinical-trials":
							return (
								<Box
									inline
									gap={4}
									paddingVertical={Spacing.xxxs}
									align="center"
								>
									<Icon name="TestTube" size={16} light />
									<Text
										light
										weight={TextWeight.medium}
										size={{ initial: TextSize.sm, "<md": TextSize.xs }}
									>
										Searched clinical trials
									</Text>
								</Box>
							);
						case "accessing-provider-profiles":
							return (
								<Box
									inline
									gap={4}
									paddingVertical={Spacing.xxxs}
									align="center"
								>
									<Icon name="FileUser" size={16} light />
									<Text
										light
										weight={TextWeight.medium}
										size={{ initial: TextSize.sm, "<md": TextSize.xs }}
									>
										Accessed provider profiles
									</Text>
								</Box>
							);
					}
				})}
			</Box>
		</Box>
	);
};

type AssistantConversationProps = {
	caption: string;
	conversation: Message[];
};

const AssistantConversation: React.FC<AssistantConversationProps> = ({
	caption,
	conversation,
}) => {
	return (
		<Box
			column
			grow
			shrink
			basis={0}
			gap={{
				initial: Spacing.xxl,
				"<md": Spacing.xl,
			}}
			// justify="space-between"
			paddingHorizontal={Spacing.sm}
			style={{ maxWidth: 380 }}
		>
			<Box align="center" gap={Spacing.sm} justify="center">
				<Text size={TextSize.base} weight={TextWeight.semibold} color="sand11">
					{caption}
				</Text>
			</Box>
			<Box column gap={16}>
				{conversation.map((item, index) => {
					switch (item.type) {
						case "user":
							return <UserMessageView key={index} content={item.content} />;
						case "assistant":
							return (
								<AssistantMessageView key={index} content={item.content} />
							);
					}
				})}
			</Box>
		</Box>
	);
};

const PersonalResearchAssistantSection: React.FC = () => (
	<Section
		title="A personal research assistant at your fingertips"
		description="No more sifting through information or waiting for answers — the Saffron Assistant provides instant, clear answers to complex questions."
	>
		<ConversationExamples
			conversation1={[
				user(
					"Are there any new treatments available for my father’s chronic musculoskeletal pain?",
				),
				assistant([
					assistantAccessingClinicalTrials(),
					assistantText(
						"A new pain management medication was approved last month that might benefit your father. Would you like information on nearby doctors prescribing it?",
					),
				]),
			]}
			caption1="Search recent information"
			conversation2={[
				user("What do reviews say about Dr. Smith vs. Dr. Choudhary?"),
				assistant([
					assistantAccessingProviderProfiles(),
					assistantText(
						"Dr. Smith has frequent complaints about long wait times, while Dr. Choudhary is praised for excellent bedside manner.",
					),
				]),
			]}
			caption2="Compare different providers"
		/>
	</Section>
);

const ContactUsButton: React.FC = () => {
	const theme = useTheme();

	const className = useStyles({
		"&:hover": {
			background: `linear-gradient(to bottom, ${whiteA.whiteA5}, ${blackA.blackA2}), ${theme.colors.accent9}`,
		},
		background: `linear-gradient(to bottom, ${whiteA.whiteA3}, ${blackA.blackA4}), ${theme.colors.accent9}`,
	});

	return (
		<a
			target="_blank"
			href="mailto:team@saffron.health"
			className={className}
			style={{
				display: "inline-block",
				padding: "5px 12px",
				borderRadius: 6,
				borderWidth: 1,
				borderColor: `rgb(0 0 0 / 0.05)`,
				boxShadow: `
        inset 0 0 0 1px ${whiteA.whiteA2},
        inset 0 2px 1px -2px ${whiteA.whiteA3},
        inset 0 1px 1px ${whiteA.whiteA6},
        inset 0 -1px 1px ${blackA.blackA6},
        inset 0 0 0 1px ${theme.colors.accentA9}`,
			}}
		>
			<ThemeProvider mode="dark">
				<Text
					size={TextSize.base}
					weight={TextWeight.medium}
					color="sand12"
					style={{ textShadow: `0 0 1px ${theme.colors.accentA9}` }}
				>
					Contact us
				</Text>
			</ThemeProvider>
		</a>
	);
};

const SchedulingSection: React.FC = () => (
	<Section
		title="Let Saffon handle the tedious coordination tasks"
		description="The Saffron Assistant handles time-consuming calls and coordination, so you can focus on what's important"
	>
		<ConversationExamples
			conversation1={[
				user("Call my insurance to ask why they denied my last claim."),
				assistant([
					assistantText(
						"Calling Cigna to check on denial status of your claim on October 5, 2024.",
					),
					assistantCallInProgress(),
				]),
			]}
			caption1="Check on the status of insurance claims"
			conversation2={[
				user("Schedule an appointment for next week with Dr. Choudhary."),
				assistant([
					assistantText(
						"Calling Dr. Choudhary's office to check availability and make an appointment.",
					),
					assistantCallCompleted(),
					assistantText(
						"Your appointment with Dr. Choudhary is scheduled for next Monday at 2:30pm.",
					),
				]),
			]}
			caption2="Book appointments"
		/>
	</Section>
);

type ConversationExamplesProps = {
	conversation1: Message[];
	caption1: string;
	conversation2: Message[];
	caption2: string;
};

const ConversationExamples: React.FC<ConversationExamplesProps> = ({
	conversation1,
	caption1,
	conversation2,
	caption2,
}) => {
	return (
		<Box
			column={{
				"<md": true,
				initial: false,
			}}
			justify="center"
			gap={{
				"<md": Spacing.xl,
				initial: Spacing.lg,
			}}
			paddingVertical={{
				"<md": Spacing.lg,
				initial: Spacing.xl,
			}}
		>
			<AssistantConversation conversation={conversation1} caption={caption1} />
			<Divider
				vertical={{
					"<md": false,
					initial: true,
				}}
				size={64}
				className="conversation-divider"
			/>
			<AssistantConversation conversation={conversation2} caption={caption2} />
		</Box>
	);
};

const Footer: React.FC = () => (
	<footer style={{ margin: 0, position: "relative" }}>
		<Box
			column
			align="center"
			style={{
				paddingTop: {
					"<md": 160,
					initial: 240,
				},
				paddingBottom: {
					"<md": 160,
					initial: 240,
				},
			}}
		>
			<div
				className="footer-gradient"
				style={{
					position: "absolute",
					inset: "-160px 0 0 0",
					zIndex: -1,
				}}
			/>
			<Box column align="center" gap={Spacing.lg}>
				<Text
					size={TextSize.xl}
					align="center"
					balance
					weight={TextWeight.semibold}
				>
					Improve your family's care and reclaim your time with Saffron{" "}
					<Text
						color="accent11"
						size={TextSize.xl}
						weight={TextWeight.semibold}
					>
						today
					</Text>
				</Text>
				<ContactUsButton />
			</Box>
		</Box>
	</footer>
);
