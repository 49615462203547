import { blackA, grayA } from "@radix-ui/colors";
import React from "react";
import { useTheme } from "./Theme";
import { ColorName } from "./colors";
import {
	mapResponsiveValue,
	Responsive,
	ResponsiveStyles,
	useResponsiveStyles,
} from "./useResponsiveStyles";

type FlexStyle = React.CSSProperties;

// Needs to be part of the theme
export enum Spacing {
	xxxs = 2,
	xxs = 4,
	xs = 8,
	sm = 12,
	md = 16,
	lg = 24,
	xl = 32,
	xxl = 40,
	xxxl = 48,
	xxxxl = 64,
}

export enum BorderWidth {
	md = 1.5,
	lg = 2,
}

export enum BorderRadius {
	md = 6,
	lg = 12,
	xl = 16,
	full = "100%",
}

export enum Shadow {
	inset,
	sm,
	md,
	lg,
	xl,
	debug,
}

const shadowStyles: Record<Shadow, string> = {
	[Shadow.inset]: `
  inset 0 0 0 1px ${grayA.grayA5},
  inset 0 1.5px 2px 0 ${grayA.grayA2},
  inset 0 1.5px 2px 0 ${blackA.blackA2}`,

	[Shadow.sm]: `
  0 0 0 1px ${grayA.grayA3},
  0 0 0 0.5px ${blackA.blackA1},
  0 1px 1px 0 ${grayA.grayA2},
  0 2px 1px -1px ${blackA.blackA1},
  0 1px 3px 0 ${blackA.blackA1}`,

	[Shadow.md]: `
  0 0 0 1px ${grayA.grayA3},
  0 8px 40px ${blackA.blackA1},
  0 12px 32px -16px ${grayA.grayA3}`,

	[Shadow.lg]: `
  0 0 0 1px ${grayA.grayA3},
  0 12px 60px ${blackA.blackA3},
  0 16px 64px ${grayA.grayA2},
  0 16px 36px -20px ${grayA.grayA7}`,

	[Shadow.xl]: `
  0px 3.1px 4.9px rgba(0, 0, 0, 0.017),
  0px 7.4px 9.9px rgba(0, 0, 0, 0.022),
  0px 14px 15.7px rgba(0, 0, 0, 0.027),
  0px 25px 23.6px rgba(0, 0, 0, 0.032),
  0px 46.8px 37.6px rgba(0, 0, 0, 0.041),
  0px 112px 80px rgba(0, 0, 0, 0.07)
`,

	[Shadow.debug]: `0 0 10px 10px red`,
};

export type BoxProps = {
	column?: Responsive<boolean>;
	gap?: Responsive<Spacing>;
	align?: Responsive<FlexStyle["alignItems"]>;
	justify?: Responsive<FlexStyle["justifyContent"]>;
	wrap?: Responsive<FlexStyle["flexWrap"] | true>;
	inline?: Responsive<boolean>;

	flex?: Responsive<FlexStyle["flex"]>;
	basis?: FlexStyle["flexBasis"];
	grow?: FlexStyle["flexGrow"] | true;
	shrink?: FlexStyle["flexShrink"] | true;
	alignSelf?: FlexStyle["alignSelf"];

	shadow?: Responsive<Shadow | true>;

	style?: ResponsiveStyles;
	className?: string;

	background?: ColorName;

	width?: Responsive<Spacing>;
	height?: Responsive<Spacing>;

	borderRadius?: BorderRadius;

	borderWidth?: Responsive<BorderWidth | undefined>;
	borderTopWidth?: Responsive<BorderWidth | undefined>;
	borderLeftWidth?: Responsive<BorderWidth | undefined>;
	borderRightWidth?: Responsive<BorderWidth | undefined>;
	borderBottomWidth?: Responsive<BorderWidth | undefined>;
	borderColor?: ColorName;

	paddingVertical?: Responsive<Spacing>;
	paddingHorizontal?: Responsive<Spacing>;

	children?: React.ReactNode;
};

export const Box: React.FC<BoxProps> = ({
	flex,
	basis,
	grow,
	shrink,
	inline,

	column,
	gap,
	align,
	justify,
	wrap,
	alignSelf,

	shadow,

	paddingVertical,
	paddingHorizontal,

	borderWidth,
	borderTopWidth,
	borderLeftWidth,
	borderRightWidth,
	borderBottomWidth,
	borderColor: borderColorName = "sand6",

	borderRadius,

	width,
	height,

	background,

	style,
	className = "",

	children,
}) => {
	const theme = useTheme();
	const backgroundColor = background ? theme.colors[background] : undefined;

	const hasBorder =
		borderWidth ||
		borderTopWidth ||
		borderLeftWidth ||
		borderRightWidth ||
		borderBottomWidth;

	const borderColor = hasBorder ? theme.colors[borderColorName] : undefined;

	const stylesClassName = useResponsiveStyles({
		flex,
		flexDirection: mapResponsiveValue(column, (value) =>
			value ? "column" : "row",
		),
		gap,
		alignItems: align,
		justifyContent: justify,
		flexWrap: mapResponsiveValue(wrap, (value) =>
			value === true ? "wrap" : value,
		),
		display: mapResponsiveValue(inline, (value) =>
			value ? "inline-flex" : "flex",
		),
		// React is weird if these props are undefined, so we need to conditionally add
		// them.
		// For border-width, set to 0 if a different border prop is provided
		// because otherwise, all the other border widths will get set to
		// browser default
		...(hasBorder && borderWidth ? { borderWidth } : { borderWidth: 0 }),
		...(hasBorder && borderTopWidth ? { borderTopWidth } : {}),
		...(hasBorder && borderLeftWidth ? { borderLeftWidth } : {}),
		...(hasBorder && borderRightWidth ? { borderRightWidth } : {}),
		...(hasBorder && borderBottomWidth ? { borderBottomWidth } : {}),
		borderStyle: hasBorder ? "solid" : undefined,

		boxShadow: mapResponsiveValue(shadow, (shadow) =>
			shadow === true
				? shadowStyles[Shadow.md]
				: shadow
					? shadowStyles[shadow]
					: undefined,
		),

		flexBasis: basis,
		flexGrow: grow === true ? 1 : grow,
		flexShrink: shrink === true ? 1 : shrink,

		alignSelf,

		backgroundColor,

		paddingTop: paddingVertical,
		paddingBottom: paddingVertical,
		paddingLeft: paddingHorizontal,
		paddingRight: paddingHorizontal,

		width,
		height,

		borderRadius,

		borderColor,
		...style,
	});

	return <div className={className + " " + stylesClassName}>{children}</div>;
};

type SpacerProps = {
	flex?: FlexStyle["flex"];
	basis?: FlexStyle["flexBasis"];
	grow?: FlexStyle["flexGrow"] | true;
	shrink?: FlexStyle["flexShrink"] | true;

	height?: Responsive<number>;
	width?: Responsive<number>;
};

export const Spacer: React.FC<SpacerProps> = ({
	flex,
	basis,
	grow,
	shrink,

	height,
	width,
}) => {
	const className = useResponsiveStyles({
		flex,
		height,
		width,
		flexBasis: basis,
		flexGrow: mapResponsiveValue(grow, (value) => (value === true ? 1 : value)),
		flexShrink: mapResponsiveValue(shrink, (value) =>
			value === true ? 1 : value,
		),
	});

	return <div className={className} />;
};
