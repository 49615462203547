import { useContext } from "react";

export function useRequiredContext<T>(
	context: React.Context<T>,
): NonNullable<T> {
	const value = useContext(context);
	if (!value) {
		throw new Error(
			`useRequiredContext must be used within a ${context.displayName} Provider`,
		);
	}
	return value;
}
