import { useInjectGlobalStyles } from "purse-styles";
import React from "react";
import { ColorName } from "./colors";
import { useTheme } from "./Theme";
import {
	mapResponsiveValue,
	Responsive,
	ResponsiveStyles,
	useResponsiveStyles,
} from "./useResponsiveStyles";
import { match } from "./utils/utils";

export enum TextWeight {
	light = "light",
	regular = "regular",
	medium = "medium",
	semibold = "semibold",
	bold = "bold",
}

export enum TextSize {
	"xxxl",
	"xxl",
	xl,
	lg,
	base,
	sm,
	xs,
}

const fontStyles: Record<
	TextSize,
	{
		fontSize: number;
		lineHeight: string;
		letterSpacing: string;
	}
> = {
	[TextSize["xxxl"]]: {
		fontSize: 28,
		lineHeight: "36px",
		letterSpacing: "-0.0075em",
	},
	[TextSize.xxl]: {
		fontSize: 24,
		lineHeight: "30px",
		letterSpacing: "-0.00625em",
	},
	[TextSize.xl]: {
		fontSize: 20,
		lineHeight: "28px",
		letterSpacing: "-0.005em",
	},
	[TextSize.lg]: {
		fontSize: 18,
		lineHeight: "26px",
		letterSpacing: "-0.0025em",
	},
	[TextSize.base]: {
		fontSize: 16,
		lineHeight: "24px",
		letterSpacing: "0",
	},
	[TextSize.sm]: {
		fontSize: 14,
		lineHeight: "20px",
		letterSpacing: "0",
	},
	[TextSize.xs]: {
		fontSize: 12,
		lineHeight: "16px",
		letterSpacing: "0.0025em",
	},
};

type TextProps = {
	as?: "span" | "p" | "label";
	size?: Responsive<TextSize>;
	style?: ResponsiveStyles;
	color?: ColorName;
	light?: boolean;
	weight?: Responsive<TextWeight>;
	align?: "left" | "center" | "right";
	italic?: boolean;
	balance?: Responsive<boolean>;
	// Use a serif font to look schmancy
	fancy?: boolean;
	truncate?: boolean;
	children?: string | (string | React.ReactNode)[];
};

export const Text: React.FC<TextProps> = ({
	as: elementName = "span",
	size,
	style,
	weight,
	light,
	color: colorName = light ? "sand11" : undefined,
	align,
	balance,
	italic,
	fancy,
	truncate = false,
	children,
}) => {
	const theme = useTheme();
	const color = colorName ? theme.colors[colorName] : undefined;
	const fontStyle = italic ? "italic" : undefined;

	const textOverflow = truncate ? "ellipsis" : undefined;
	const whiteSpace = truncate ? "nowrap" : undefined;
	const overflow = truncate ? "hidden" : undefined;

	const className = useResponsiveStyles({
		fontSize: mapResponsiveValue(size, (size) =>
			size ? fontStyles[size].fontSize : undefined,
		),
		letterSpacing: mapResponsiveValue(size, (size) =>
			size ? fontStyles[size].letterSpacing : undefined,
		),
		lineHeight: mapResponsiveValue(size, (size) =>
			size ? fontStyles[size].lineHeight : undefined,
		),
		fontFamily: mapResponsiveValue(fancy, (fancy) =>
			fancy ? "Newsreader" : undefined,
		),
		fontWeight: mapResponsiveValue(weight, (weight) =>
			weight
				? match(weight, {
						light: 300,
						regular: 400,
						medium: 500,
						semibold: 600,
						bold: 700,
					})
				: undefined,
		),
		textWrap: mapResponsiveValue(balance, (balance) =>
			balance ? "balance" : undefined,
		),
		color,
		textAlign: align,
		fontStyle,
		textOverflow,
		whiteSpace,
		overflow,
		margin: 0,
		...style,
	});

	return React.createElement(
		elementName,
		{ className: className + " " + baseTextStylesClassName },
		children,
	);
};

const baseTextStylesClassName = "base-text-styles";

export const GlobalTextStyles: React.FC = () => {
	useInjectGlobalStyles(
		`.${baseTextStylesClassName}`,
		{ ...fontStyles[TextSize.base] },
		[],
	);

	return null;
};
