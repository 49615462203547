import { CSSProperties, useStyles } from "purse-styles";

export function mapResponsiveValue<I, O>(
  responsiveValue: Responsive<I>,
  mapFunction: (value: I) => O,
): Responsive<O> {
  if (typeof responsiveValue === "object") {
    // @ts-ignore
    const mappedValue = Object.entries(responsiveValue).reduce(
      (acc, [key, value]) => {
        // @ts-ignore
        acc[key] = mapFunction(value);
        return acc;
      },
      {},
    );

    return mappedValue as Responsive<O>;
  }

  return mapFunction(responsiveValue);
}

enum ScreenSize {
  initial = 0,
  // Phones (portrait)
  "<sm" = 520,
  // Phones (landscape)
  "<md" = 768,
  // Tables (portrait)
  "<lg" = 1024,
  // Tablets (landscape)
  "<xl" = 1280,
  // Desktops
}

export type Responsive<Value> =
  | Value
  | (
    & {
      [Key in Exclude<keyof typeof ScreenSize, number>]?: Value;
    }
    & { initial: Value }
  );

export type ResponsiveStyles = {
  [Key in keyof CSSProperties]?: Responsive<CSSProperties[Key]> | undefined;
};

const emptyObject = {};

export function useResponsiveStyles(
  responsiveStyles: ResponsiveStyles = emptyObject,
) {
  type GroupedStyles =
    & {
      [Key in Exclude<keyof typeof ScreenSize, number>]?: CSSProperties;
    }
    & { initial: CSSProperties };

  const groupedStyles = Object.entries(responsiveStyles).reduce<GroupedStyles>(
    (acc, [key, value]) => {
      if (typeof value === "object") {
        Object.entries(value).forEach(([untypedScreenSize, styleValue]) => {
          const screenSize = untypedScreenSize as keyof typeof ScreenSize;
          if (!acc[screenSize]) {
            acc[screenSize] = {};
          }

          // @ts-ignore
          acc[screenSize][key] = styleValue;
        });
      } else {
        // @ts-ignore
        acc.initial[key] = value;
      }

      return acc;
    },
    { initial: {} } satisfies GroupedStyles,
  );

  const initialStyles = groupedStyles.initial;

  const className = useStyles(initialStyles, {
    [`@media (max-width: ${ScreenSize["<sm"]}px)`]: groupedStyles["<sm"],
    [`@media (max-width: ${ScreenSize["<md"]}px)`]: groupedStyles["<md"],
    [`@media (max-width: ${ScreenSize["<lg"]}px)`]: groupedStyles["<lg"],
    [`@media (max-width: ${ScreenSize["<xl"]}px)`]: groupedStyles["<xl"],
  });

  return className;
}
