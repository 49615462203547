import { useStyles } from "purse-styles";
import React, { useRef, useState } from "react";
import { Button, Dialog, DialogTrigger, Popover } from "react-aria-components";
import { BorderRadius, Box, Shadow, Spacer, Spacing } from "./Box";
import { Icon } from "./Icon";
import { Text, TextSize } from "./Text";
import { useTheme } from "./Theme";

interface TooltipProps {
	content: string;
	source: string;
	url: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, source }) => {
	const theme = useTheme();
	const [isOpen, onOpenChange] = useState<boolean>(false);
	const tooltipRef = useRef<HTMLDivElement | null>(null);

	const focusStyle = useStyles({
		["&:focus-visible[data-focus-visible]"]: {
			boxShadow: `0 0 0 1.5px ${theme.colors.accent9}`,
		},

		boxShadow: "none",
		transition: "box-shadow 0.12s ease-in-out",
		outline: "none",
	});

	return (
		<DialogTrigger onOpenChange={onOpenChange} isOpen={isOpen}>
			<Button
				className={focusStyle}
				style={{
					cursor: "pointer",
					width: 16,
					height: 16,
					borderRadius: "100%",
					padding: 1,
					transform: `translateY(2px)`,
				}}
			>
				<Icon
					name={"Info"}
					light
					size={14}
					color={isOpen ? "sand2" : "sand11"}
					fill={isOpen ? "accent9" : undefined}
				/>
			</Button>
			<Popover>
				<Dialog className={focusStyle} style={{ padding: 2, borderRadius: 4 }}>
					<Box
						column
						background="sand4"
						paddingHorizontal={Spacing.sm}
						paddingVertical={Spacing.sm}
						borderRadius={BorderRadius.md}
						shadow={Shadow.md}
						style={{ width: "clamp(250px, 30vw, 400px)" }}
					>
						<Text size={TextSize.sm} color="sand12">
							{content}
						</Text>
						<Spacer height={Spacing.xs} />
						<span>
							<a
								href={"https://doi.org/10.26419/ppi.00103.001"}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Text size={TextSize.xs} color="sand10" italic>
									{source}
								</Text>
							</a>
						</span>
					</Box>
				</Dialog>
			</Popover>
		</DialogTrigger>
	);

	return (
		<div
			style={{
				position: "relative",
				display: "inline-block",
				maxWidth: "100%",
			}}
			ref={tooltipRef}
		>
			<Box align="center" gap={Spacing.xxs} style={{ maxWidth: "100%" }}></Box>
			{isOpen && (
				<div
					style={{
						position: "absolute",
						top: "100%",
						left: "50%",
						transform: "translateX(-50%)",
						width: "clamp(250px, 30vw, 400px)",
						backgroundColor: "#333",
						color: "#fff",
						padding: "10px",
						borderRadius: "5px",
						marginTop: "10px",
						boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
						textAlign: "center",
						zIndex: 3,
					}}
				>
					<Text size={TextSize.sm} color="sand12">
						{content}
					</Text>
					<Spacer height={5} />
					<span>
						<a
							href={"https://doi.org/10.26419/ppi.00103.001"}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Text size={TextSize.xs} color="sand10" italic>
								{source}
							</Text>
						</a>
					</span>
				</div>
			)}
		</div>
	);
};
